import React, { useState, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import TransactionBtn from "./transactionBtn";

const MyAgGridComponent = ({ colDefs, gridData }) => {
  const [gridApi, setGridApi] = useState(null); // State for gridApi
  const [defaultColDef, setDefaultColDef] = useState({
    sortable: true,
    filter: true,
    resizable: true,
  });

  const [frameworkComponents, setFrameworkComponents] = useState({
    TransactionBtn: TransactionBtn,
  });

  const [statusBar, setStatusBar] = useState({
    statusPanels: [
      { statusPanel: "agTotalRowCountComponent", align: "left" },
      { statusPanel: "agFilteredRowCountComponent" },
      { statusPanel: "agSelectedRowCountComponent" },
      { statusPanel: "agAggregationComponent" },
    ],
  });

  const [sideBar, setSideBar] = useState({
    toolPanels: ["columns", "filters"],
  });

  const [paginationPageSize, setPaginationPageSize] = useState(20);

  const [rowClassRules, setRowClassRules] = useState({
    "row-highlight": (params) => params.data && params.data.highlight,
  });

  const [detailCellRendererParams, setDetailCellRendererParams] = useState({
    detailGridOptions: {
      columnDefs: [{ field: "name" }, { field: "value" }],
    },
    getDetailRowData: (params) => {
      params.successCallback(params.data.detailData);
    },
  });

  useEffect(() => {
    if (gridApi) {
      // Reset filters whenever gridData changes
      gridApi.setFilterModel(null);
    }
  }, [gridData, gridApi]); // Add gridData and gridApi as dependencies

  const onGridReady = (params) => {
    setGridApi(params.api); // Save the gridApi instance
  };

  return (
    <div className="ag-theme-alpine" style={{ height: "700px", width: "100%" }}>
      <AgGridReact
        columnDefs={colDefs}
        defaultColDef={defaultColDef}
        rowData={gridData}
        enableCharts={false}
        onGridReady={onGridReady}
        frameworkComponents={frameworkComponents}
        statusBar={statusBar}
        sideBar={sideBar}
        stopEditingWhenGridLosesFocus={true}
        paginationPageSize={paginationPageSize}
        pagination={true}
        enableRangeSelection={true}
        enableCellContextMenu={true}
        suppressContextMenu={false}
        masterDetail={true}
        detailCellRendererParams={detailCellRendererParams}
        rowClassRules={rowClassRules}
      />
    </div>
  );
};

export default MyAgGridComponent;
