import React, { Component } from "react";
import redirectURL from "../redirectURL";
import SweetAlert from "react-bootstrap-sweetalert";
import { getDDMMYYYYHHMM, getHyphenYYYYMMDDHHMMSS } from ".././common/utils";
import { AgGridReact } from 'ag-grid-react';
import { AllModules } from "@ag-grid-enterprise/all-modules";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'ag-grid-community/styles/ag-grid.css';
import "ag-grid-community/styles/ag-theme-balham.css"; // Optional theme CSS
import "ag-grid-enterprise";
var moment = require("moment");
export default class BillingSummaryDashboard extends Component {
  constructor(props) {
    super(props);
    const currentDate = moment().subtract(1, "months"); // Previous month
    if (!currentDate.isValid()) {
      console.error("Invalid initial date"); // Debugging invalid date
    }
    this.state = {
      alertshow: "fade",
      alertmg: "show-n",
      alerterrmg: "show-n",
      overly: "show-n",
      alerterrshow: "fade",
      alert: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      modules: AllModules,
      filterstatess: {},
      columnsdatas: {},
      rowgroupdcols: [],
      valuecolumns: [],
      activeconsignemnt: [],
      mobile_network: "",
      activetrucks: [],
      transitdelays: [],
      gpsdatana: [],
      overspeedtrucks: [],
      nightdrive: [],
      pivotmodeis: false,
      sliderCommentTranslate: "",
      commentsRowData: [],
      pivotcols: [],
      defTransitCoords: "",
      routeTruck: {},
      sidebarSubHeader: "",
      slideBar: "",
      truckInfo: "",
      consignments: "",
      consignment_date: "",
      invoice_date: "",
      currenttrucklocation: "",
      defaultColDef: {
        sortable: true,
        filter: true,
        editable: false,
        resizable: true,
        minWidth: 70,
        initialWidth: 200,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      rownode: [],
      rowData: [],
      maprowData: [],
      rowSelection: "multiple",
      rowModelhighchartoptionsType: "enterprise",
      rowGroupPanelShow: "always",
      overlayNoRowsTemplate: "",
      waiting: false,
      frameworkComponents: {},
      animateRows: true,
      debug: true,
      showToolPanel: false,
      uppressAggFuncInHeader: true,
      childconfs: [],
      childrow: [],
      rowModelType: "serverSide",
      paginationPageSize: 1000,
      cacheBlockSize: 10,
      maxBlocksInCache: 1,
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left",
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center",
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" },
        ],
      },
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
      },
      selectedDate: currentDate, // Set to previous month
      selectedMonth: currentDate.month(), // Current month (0-based index)
      selectedYear: currentDate.year(), // Current year
      fromDate: this.getMonthStartDate(currentDate),
      toDate: this.getMonthEndDate(currentDate),
      billingData: [],
      rawBillingData: [],
      plantLevelBillSummary: [],
      showAlert: false,
      alertTitle: "",
      alertType: "danger",
      loadshow: "show-n", // Loader state
      overly: "show-n",
      validMonth: currentDate.format("YYYY-MM"),
      activeTab: 'summary', // Default active tab
    };
  }
  // Function to get the start date of a given month
  getMonthStartDate(date) {
    return date.startOf("month").format("YYYY-MM-DD");
  }
  // Function to get the end date of a given month
  getMonthEndDate(date) {
    return date.endOf("month").format("YYYY-MM-DD");
  }
  // Fetch data for all tables with selected month and year
  fetchBillingData = async () => {
    const { fromDate, toDate, selectedYear } = this.state;
    this.setState({ loadshow: "show-m", overly: "show-m" });
    const params = { fromDate, toDate, selectedYear }; // Adding selectedYear to params
    try {
      console.log(params, 'paramscheck')
      const response = await redirectURL.post(
        "tracking/getBillingSummaryData",
        params
      );
      const records = response.data;
      const { billingSummaryData = [], rawBillingData = [], plantBillSummaryData = [] } = records;
      this.setState({
        billingData: billingSummaryData,
        rawBillingData: rawBillingData,
        plantLevelBillSummary: plantBillSummaryData,
        loadshow: "show-n",
        overly: "show-n",
        showAlert: false, // Close alert on success
      });
    } catch (error) {
      console.log(error, 'error')
      this.setState({
        showAlert: true,
        alertTitle: "Failed to Fetch Data",
        alertType: "danger",
        loadshow: "show-n",
        overly: "show-n",
      });
    }
  };
  hideSlideBlock = () => {
    this.setState({
      overlayBlock: "show-n",
      showSlideBlock: "",
    });
  };
  componentDidMount() {
    this.fetchBillingData();
  }
  // Handle date change from DatePicker
  handleDateChange = (date) => {
    const selectedDate = moment(date);
    if (!selectedDate.isValid()) {
      console.error("Invalid selected date"); // Debugging invalid date
    }
    this.setState({
      selectedDate,
      selectedYear: selectedDate.year(),
      selectedMonth: selectedDate.month(),
      fromDate: this.getMonthStartDate(selectedDate),
      toDate: this.getMonthEndDate(selectedDate),
      validMonth: selectedDate.format("YYYY-MM"),
    });
  };
  // Handle tab switching
  onClickTab = async (screen_type) => {
    await this.setState({ activeTab: screen_type });
    // this.fetchBillingData(); // Fetch data when switching tabs
  };
  render() {
    const { billingData, rawBillingData, plantLevelBillSummary, activeTab } = this.state;
    const plantOnboardingData = billingData.filter(item => item.billing_type && item.plant_installation_date);
    const totalTripsCount = billingData.reduce(
      (acc, curr) => acc + (curr.no_of_trips_planned || 0),
      0
    );
    const totalCostPerUnit = billingData.reduce(
      (acc, curr) => acc + (curr.cost_per_unit || 200),
      0
    );
    const totalBillableAmount = billingData.reduce(
      (acc, curr) =>
        acc + (curr.no_of_trips_planned || 0) * (curr.cost_per_unit || 200),
      0
    );
    const totalCost = plantOnboardingData.reduce(
      (acc, curr) => acc + (curr.no_of_trips_planned || 0) * (curr.cost_per_unit || 200) + 50000,
      0
    );
    const totalBillingAmount = totalBillableAmount + totalCost;
    const columnDefs = [
      { headerName: "Dispatch Type", field: "dispatch_type", sortable: true, filter: true, width: 120 },
      { headerName: "Region", field: "region", sortable: true, filter: true, width: 100 },
      { headerName: "Country", field: "country", sortable: true, filter: true, width: 100 },
      { headerName: "Plant Zone", field: "plant_zone", sortable: true, filter: true, width: 100 },
      { headerName: "DC / Plant Name", field: "plant_name", sortable: true, filter: true, width: 100, },
      { headerName: "Plant Code", field: "plant_code", sortable: true, filter: true, width: 100, },
      { headerName: "Location Code", field: "location_code", sortable: true, filter: true, width: 100, },
      { headerName: "Location Name", field: "location_name", sortable: true, filter: true, width: 140, },
      { headerName: "Location Unique ID", field: "location_unique_id", sortable: true, filter: true, width: 120, },
      { headerName: "Customer Type", field: "customer_type", sortable: true, filter: true, width: 100 },
      { headerName: "Dealer Type", field: "dealer_type", sortable: true, filter: true, width: 100 },
      // { headerName: "Location Address", field: "location_address", sortable: true, filter: true, width: 140, },
      // { headerName: "Location City", field: "location_city", sortable: true, filter: true, width: 120, },
      // { headerName: "Location State", field: "location_state", sortable: true, filter: true, width: 120, },
      // { headerName: "Location Zone", field: "location_zone", sortable: true, filter: true, width: 100, },
      // { headerName: "Distance From Plant", field: "distance_from_plant", sortable: true, filter: true, width: 100 },
      // { headerName: "Distance Matrix Process", field: "distance_matrix_process", sortable: true, filter: true, width: 100 },
      // { headerName: "Pickup From Time", field: "pickup_from_time", sortable: true, filter: true, width: 100 },
      // { headerName: "Pickup To Time", field: "pickup_to_time", sortable: true, filter: true, width: 100 },
      // { headerName: "Drop From Time", field: "drop_from_time", sortable: true, filter: true, width: 100 },
      // { headerName: "Drop To Time", field: "drop_to_time", sortable: true, filter: true, width: 100 },
      // { headerName: "Standard Transit Time", field: "standard_transit_time", sortable: true, filter: true, width: 100 },
      // { headerName: "Location Threshold Time", field: "location_threshold_time", sortable: true, filter: true, width: 100 },
      // { headerName: "Status", field: "status", sortable: true, filter: true, width: 100 },
      // { headerName: "Vehicle List", field: "vehicle_list", sortable: true, filter: true, width: 100 },
      // { headerName: "Week Off", field: "week_off", sortable: true, filter: true, width: 100 },
      // { headerName: "Zone", field: "zone", sortable: true, filter: true, width: 100 },
      // { headerName: "CSP Day List", field: "csp_day_list", sortable: true, filter: true, width: 100 },
      // { headerName: "KDD Customer", field: "kdd_customer", sortable: true, filter: true, width: 100 },
      // { headerName: "KDD Finite Period", field: "kdd_finite_period", sortable: true, filter: true, width: 100 },
      // { headerName: "KDD Period Start Month", field: "kdd_period_start_month", sortable: true, filter: true, width: 100 },
      // { headerName: "KDD Period End Month", field: "kdd_period_end_month", sortable: true, filter: true, width: 100 },
      { headerName: "No of Trips Planned", field: "no_of_trips_planned", sortable: true, filter: true, width: 100 },
      { headerName: "No of Trips Assigned", field: "no_of_trips_Assined", sortable: true, filter: true, width: 100 },
      { headerName: "Year-Month", field: "year_month", sortable: true, filter: true, width: 100 },
      { headerName: "Billing Type", field: "billing_type", sortable: true, filter: true, width: 100 },
      { headerName: "Billing Unique ID", field: "billing_unique_id", sortable: true, filter: true, width: 140 },
      {
        headerName: "Updated Date", field: "updated_date", sortable: true, filter: true, width: 140,
        valueGetter: (params) => {
          let { updated_date } = params.data;
          return updated_date ? getDDMMYYYYHHMM(updated_date) : "";
        }
      },
      {
        headerName: "Created Date", field: "created_date", sortable: true, filter: true, width: 140,
        valueGetter: (params) => {
          let { created_date } = params.data;
          return created_date ? getDDMMYYYYHHMM(created_date) : "";
        }
      },
    ];
    const tableDataStyle = {
      color: 'black', // Set text color to white
      backgroundColor: 'white', // Example: dark background for better contrast
      padding: '8px',
    };
    const backGroundColorTable = {
      backgroundColor: '#4690b3',
      color: "white"
    }
    const tableAlignment = { textAlign: "center" }
    return (
      <>
        {this.state.showAlert && (
          <SweetAlert
            type={this.state.alertType}
            title={this.state.alertTitle}
            onConfirm={() => this.setState({ showAlert: false })}
          />
        )}
        <div className="row">
          <div className="col-sm-12">
            <ul className="d-tabs">
              <li
                onClick={() => this.onClickTab('summary')}
                className={this.state.activeTab === 'summary' ? 'lttabs activet' : 'lttabs'}
              >
                <button type="button" className="btn">
                  Summary Data {" "}
                </button>
              </li>
              <li
                onClick={() => this.onClickTab('rawtrips')}
                className={this.state.activeTab === 'rawtrips' ? 'lttabs activet' : 'lttabs'}
              >
                <button type="button" className="btn mt-30p">
                  Raw Trips Data {" "}
                </button>
              </li>
            </ul>
          </div>
          <div className="col-sm-12" style={{ marginTop: "18px" }}>
            <h5 className="fbold ">
              <span>Billing Summary Dashboard</span>
            </h5>
          </div>
        </div>
        <div className="col-md-12 ml-0 pl-0 mb-0 pb-0 mt-2" >
          <div className="row mb-4">
            <div className="col-xl-2 col-lg-3 col-md-4 col-sm-12 form-group" >
              <label>Select Month</label>
              <DatePicker
                className="form-control"
                selected={this.state.selectedDate.toDate()} // Convert moment to Date object
                onChange={this.handleDateChange}
                dateFormat="MMM yyyy" // Format as 'Jan 2025'
                showMonthYearPicker
                style={{ padding: "5px", width: "100%" }}
                minDate={moment().subtract(10, "years").toDate()} // Min date 10 years ago
                maxDate={moment().toDate()} // Max date today
                onKeyDown={(e) => e.preventDefault()} // Prevent manual input by blocking keydown events
                onInput={(e) => e.preventDefault()}
              />
            </div>
            <div className="col-xl-2 col-lg-2 col-md-3 col-sm-12 form-group">
              <button
                className="btn btn-warning"
                onClick={this.fetchBillingData}
                style={{
                  marginBottom: '10px',
                  marginTop: '28px',
                  padding: '8px',
                  width: 'auto',
                  marginLeft: "-10px",
                  paddingBottom: "9px",
                }}
              >
                Get Data
              </button>
            </div>
          </div>
        </div>
        <div className="">
          <div className="container-fluid pl-0">
            {activeTab === 'summary' ? (
              <>
                <div className="col-md-12 pl-0 mb-0 pb-0">
                  <table className="table table-bordered" style={tableAlignment}>
                    <thead style={backGroundColorTable}>
                      <tr>
                        <th colSpan="4" className="ol-form-label">Overall Billing Summary (Trips)</th>
                      </tr>
                    </thead>
                    <thead style={backGroundColorTable}>
                      <tr>
                        <th className="ol-form-label">Description</th>
                        <th className="ol-form-label">Trips Count</th>
                        <th className="ol-form-label">Cost Per Unit</th>
                        <th className="ol-form-label">Billable Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {billingData.length > 0 ? (
                        <tr>
                          <td style={tableDataStyle}><strong>No. of Trips Planned</strong></td>
                          <td style={tableDataStyle}><strong>{totalTripsCount}</strong></td>
                          <td style={tableDataStyle}><strong>200</strong></td>
                          <td style={tableDataStyle}><strong>{totalBillableAmount}</strong></td>
                        </tr>
                      ) : (
                        <tr><td style={tableDataStyle} colSpan="4">No data available</td></tr>
                      )}
                    </tbody>
                  </table>
                  <table className="table table-bordered" style={tableAlignment}>
                    <thead style={backGroundColorTable}>
                      <tr>
                        <th colSpan="3" className="ol-form-label">New DC / Plant Onboarding</th>
                      </tr>
                    </thead>
                    <thead style={backGroundColorTable}>
                      <tr>
                        <th className="ol-form-label">DC Name</th>
                        <th className="ol-form-label">Go-Live Date</th>
                        <th className="ol-form-label">Cost Per Setup</th>
                      </tr>
                    </thead>
                    <tbody>
                      {plantOnboardingData.length > 0 ? (
                        plantOnboardingData.map((row, index) => (
                          <tr key={index}>
                            <td style={tableDataStyle}>{row.plant_name}</td>
                            <td style={tableDataStyle}>{getDDMMYYYYHHMM(row.plant_installation_date)}</td>
                            <td style={tableDataStyle}>50000</td>
                          </tr>
                        ))
                      ) : (
                        <tr><td style={tableDataStyle} colSpan="3">No plants were onboarded in the selected month</td></tr>
                      )}
                      {plantOnboardingData.length > 0 && (
                        <tr>
                          <td style={tableDataStyle}><strong>Total</strong></td>
                          <td style={tableDataStyle}></td>
                          <td style={tableDataStyle}><strong>{totalCost}</strong></td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="col-md-12 pl-0 mb-0 pb-0" >
                  <table className="table table-bordered" style={tableAlignment}>
                    <thead style={backGroundColorTable}>
                      <tr>
                        <th className="ol-form-label col-lg-6 col-xl-6" style={{ textAlign: "left" }}>
                          Total Billing Amount
                        </th>
                        <th className="ol-form-label col-lg-6 col-xl-6" style={{ textAlign: "left" }}>
                          {totalBillingAmount}
                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>
                <div className="col-md-12 pl-0 mb-0 pb-0">
                  <table className="table table-bordered" style={tableAlignment}>
                    <thead style={backGroundColorTable}>
                      <tr>
                        <th colSpan="7" className="ol-form-label">DC / Plant Billing Summary (Trips)</th>
                      </tr>
                    </thead>
                    <thead style={backGroundColorTable}>
                      <tr>
                        <th className="ol-form-label">DC / Plant Name</th>
                        <th className="ol-form-label">Primary / Secondary</th>
                        <th className="ol-form-label">SAR</th>
                        <th className="ol-form-label">Branch</th>
                        <th className="ol-form-label">No. of Trips Planned</th>
                        <th className="ol-form-label">Cost Per Unit</th>
                        <th className="ol-form-label">Billable Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {plantLevelBillSummary.length > 0 ? (
                        plantLevelBillSummary.map((row, index) => (
                          <tr key={index}>
                            <td style={tableDataStyle}>{row.plant_name}</td>
                            <td style={tableDataStyle}>{row.dispatch_type}</td>
                            <td style={tableDataStyle}>{row.country}</td>
                            <td style={tableDataStyle}>{row.plant_zone || ""}</td>
                            <td style={tableDataStyle}>{row.no_of_trips_planned || 0}</td>
                            <td style={tableDataStyle}>200</td>
                            <td style={tableDataStyle}>{(row.no_of_trips_planned || 0) * 200}</td>
                          </tr>
                        ))
                      ) : (
                        <tr><td style={tableDataStyle} colSpan="7">No data available</td></tr>
                      )}
                      {plantLevelBillSummary.length > 0 && (
                        <tr>
                          <td style={tableDataStyle}><strong>Total</strong></td>
                          <td style={tableDataStyle}></td> {/* Empty cell for Primary/Secondary */}
                          <td style={tableDataStyle}></td> {/* Empty cell for SAR */}
                          <td style={tableDataStyle}></td> {/* Empty cell for Branch */}
                          <td style={tableDataStyle}><strong>{plantLevelBillSummary.reduce((acc, row) => acc + (row.no_of_trips_planned || 0), 0)}</strong></td>
                          <td style={tableDataStyle}></td> {/* Empty cell for Cost Per Unit */}
                          <td style={tableDataStyle}><strong>{plantLevelBillSummary.reduce((acc, row) => acc + ((row.no_of_trips_planned || 0) * 200), 0)}</strong></td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </>
            ) : (
              <div className="col-md-12 pl-0 mb-0 pb-3">
                <div id="myGrid2" style={{ height: "580px", width: "100%" }} className={"ag-theme-balham"}>
                  <AgGridReact
                    columnDefs={columnDefs}
                    rowData={rawBillingData}
                    defaultColDef={this.state.defaultColDef}
                    pagination={true}
                    paginationPageSize={this.state.paginationPageSize}
                    statusBar={this.state.statusBar}
                    sideBar={this.state.sideBar}
                    rowSelection={this.state.rowSelection}
                    gridOptions={{
                      context: { componentParent: this },
                      getContextMenuItems: (params) => {
                        return [
                          "copy", // Default AG Grid options like copy
                          "copyWithHeaders",
                          "paste",
                          {
                            name: "Export to CSV", // Custom option for exporting to CSV
                            action: () => {
                              params.api.exportDataAsCsv();
                            },
                          },
                          {
                            name: "Export to Excel", // Custom option for exporting to Excel
                            action: () => {
                              params.api.exportDataAsExcel();
                            },
                          },
                        ];
                      },
                    }}
                    onGridReady={this.onGridReady}
                    onRowSelected={this.onRowSelection}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={`loader ${this.state.loadshow}`}></div>
        <div className={"overlay-part " + this.state.overly} onClick={this.hideSlideBlock.bind(this)}></div>
      </>
    );
  }
}
