import React from "react";

const TransactionBtn = (props) => {
  let data = props.data;
  let { transaction_id } = data;
  const encryptedstring = window.btoa(data.transaction_id);
  const url =
    window.location.origin + "/viewdispatchplandetails?" + encryptedstring;
  return (
    <>
      <a
        href={url}
        target="_blank"
        key={"index"}
        style={{
          fontWeight: "bold",
          textDecoration: "none",
        }}
      >
        <span style={{ textDecoration: "underline" }}>{transaction_id}</span>
        &nbsp;
      </a>
    </>
  );
};

export default TransactionBtn;
