import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Drilldown from "highcharts/modules/drilldown";

// Initialize the drilldown module
Drilldown(Highcharts);

const DrilldownChartComponent = ({
  title = "Drilldown Chart",
  subtitle = "",
  xaxisTitle = "Categories",
  yaxisTitle = "Values",
  series = [],
  drilldownSeries = [],
  name = "main series",
  noDataMessage = "No data to display",
}) => {
  // Process main series to add drilldown IDs and set colors
  const processedSeries = series.map((point) => ({
    ...point,
    drilldown: point.drilldown, // Add drilldown ID for matching
    color: point.y < 0 ? "#f54a47" : "#7DDA58", // Red for negative, Green for positive
  }));

  // Process drilldown series
  const processedDrilldownSeries = drilldownSeries.map((drill) => ({
    id: drill.id, // Drilldown series identifier
    name: drill.name,
    data: drill.data.map((point) => ({
      ...point,
      color: point.y < 0 ? "#f54a47" : "#7DDA58", // Red for negative, Green for positive
    })),
  }));

  // Calculate min and max for yAxis to ensure 0 stays in the middle
  const values = series.map((s) => s.y);
  const minValue = Math.min(...values, 0);
  const maxValue = Math.max(...values, 0);
  const absMax = Math.max(Math.abs(minValue), Math.abs(maxValue));

  const highchartOptions = {
    chart: {
      type: "column",
    },
    title: {
      text: title,
    },
    subtitle: {
      text: subtitle,
    },
    accessibility: {
      announceNewData: {
        enabled: true,
      },
    },
    xAxis: {
      type: "category",
      title: {
        text: xaxisTitle,
      },
    },
    yAxis: {
      title: {
        text: yaxisTitle,
      },
      plotLines: [
        {
          value: 0,
          color: "black",
          width: 2,
          zIndex: 3,
        },
      ],
      min: -absMax,
      max: absMax,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          format: "{point.y}",
        },
      },
    },
    tooltip: {
      headerFormat: "<span style='font-size:10px'>{series.name}</span><br>",
      pointFormat:
        "<span style='color:{point.color}'>{point.name}</span>: <b>{point.y}</b><br/>",
    },
    series: [
      {
        name: name,
        colorByPoint: true,
        data: processedSeries,
      },
    ],
    drilldown: {
      series: processedDrilldownSeries,
    },
    lang: {
      noData: noDataMessage,
    },
    noData: {
      style: {
        fontWeight: "bold",
        fontSize: "15px",
        color: "#303030",
      },
    },
    credits: {
      enabled: false,
    },
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <HighchartsReact
        highcharts={Highcharts}
        options={highchartOptions}
        containerProps={{ style: { width: "100%", height: "100%" } }}
      />
    </div>
  );
};

export default DrilldownChartComponent;
