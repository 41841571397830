import React, { useState, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import "./plananalysis.css";
import redirectURL from "../redirectURL";
import MyAgGridComponent from "./myAgGrid";
import moment from "moment";
import { LiaUserEditSolid } from "react-icons/lia";
import { MdRebaseEdit } from "react-icons/md";
import { MdAutoDelete } from "react-icons/md";
import { getHyphenDDMMYYYY } from "../common/utils";
import { LuIndianRupee } from "react-icons/lu";
import { FaTruck } from "react-icons/fa";
import SweetAlert from "react-bootstrap-sweetalert";
import { GiPathDistance } from "react-icons/gi";
import GroupColumnChartComponent from "./columnsChart";
import DrilldownChartComponent from "./drillDownChart";
import { FaChartSimple } from "react-icons/fa6";
import { SiSoundcharts } from "react-icons/si";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import { IoIosArrowDropupCircle } from "react-icons/io";
import { IoCaretUpSharp, IoCaretDownSharp } from "react-icons/io5";
import Switch from "react-switch";

const PlanAnalysis = () => {
  const [filterType, setFilterType] = useState({
    value: "orderDate",
    label: "Plan Creation Date",
  });
  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));
  const [zone, setZone] = useState({ label: "All", value: "" });
  const [plant, setPlant] = useState({ label: "All", value: "" });
  const [zoneOptions, setZoneOptions] = useState([]);
  // const [locationOptions, setlocationOptions] = useState([]);
  const [totalTripsPlanned, setTotalTripsPlanned] = useState(0);
  const [totalTripsModified, setTotalTripsModified] = useState(0);
  const [totalTripsRemoved, setTotalTripsRemoved] = useState(0);
  const [totalCostImpact, setTotalCostImpact] = useState({
    value: 0,
    lable: true,
  });
  const [vehTypeChanges, setvehTypeChanges] = useState(0);
  const [vfrImapach, setvfrImapach] = useState({ value: 0, lable: true });
  const [disnactImapact, setDistanceImpact] = useState({
    value: 0,
    lable: true,
  });
  const [dealerChangedTrips, setdealerChangedTrips] = useState(0);
  const [rowData, setRowData] = useState([]);
  const [tripData, setTripData] = useState([]);
  const [boundLocations, setboundLocations] = useState([]);
  const [show, setShow] = useState(false);
  const [basicTitle, setBasicTitle] = useState("");
  const [basicType, setBasicType] = useState(false);
  const [costData, setCostData] = useState([]);
  const [costPerCaseData, setCostPerCaseData] = useState([]);
  const [tripModification, setTripModification] = useState([]);
  const [distanceImpactData, setDistanceImpactData] = useState([]);
  const [vfrImpactData, setVfrImpactData] = useState([]);
  const [dailyvfrImpactData, setDailyVfrImpactData] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [loadshow, setLoadShow] = useState("show-n");
  const [overlayBlock, setOverlayBlock] = useState("show-n");

  useEffect(() => {
    boundTypeLocations({ data_type: 2 });
    getPlanAnalysisData();
    getPlanAnalysisCharts();
  }, []);
  const getPlanAnalysisCharts = () => {
    setOverlayBlock("show-m");
    setLoadShow("show-m");
    let params = {
      fromDate: fromDate,
      toDate: toDate,
      zone: zone.value,
      plant: plant.value,
      dateType: filterType.value,
    };
    redirectURL.post("tsp/getPlanAnalysisCharts", params).then((response) => {
      let data = response.data;
      let {
        cost_impact_data,
        cost_per_case_impact_data,
        distance_impact_data,
        trip_modification_data,
        vfr_impact_data,
        daily_vfr_impact_data,
      } = data;
      cost_impact_data = JSON.parse(cost_impact_data);
      cost_per_case_impact_data = JSON.parse(cost_per_case_impact_data);
      distance_impact_data = JSON.parse(distance_impact_data);
      trip_modification_data = JSON.parse(trip_modification_data);
      vfr_impact_data = JSON.parse(vfr_impact_data);
      daily_vfr_impact_data = JSON.parse(daily_vfr_impact_data);
      if (response.status === 200) {
        setCostPerCaseData(cost_per_case_impact_data);
        setDistanceImpactData(distance_impact_data);
        setTripModification(trip_modification_data);
        setCostData(cost_impact_data);
        setVfrImpactData(vfr_impact_data);
        setDailyVfrImpactData(daily_vfr_impact_data);
      }
      setLoadShow("show-n");
      setOverlayBlock("show-n");
    });
  };
  const hideSlideBlock = () => {
    setOverlayBlock("show-n");
    setLoadShow("show-n");
  };
  const [fromDate, setFromDate] = useState(
    moment().subtract(7, "days").format("YYYY-MM-DD")
  );
  const closeAlert = async () => {
    setShow(false);
    setLoadShow("show-n");
    setOverlayBlock("show-n");
  };
  const getPlanAnalysisData = () => {
    setLoadShow("show-m");
    setOverlayBlock("show-m");
    let params = {
      fromDate: fromDate,
      toDate: toDate,
      zone: zone.value,
      plant: plant.value,
      dateType: filterType.value,
    };
    redirectURL.post("tsp/getPlanAnalysisData", params).then((response) => {
      let data = response.data,
        tripsModified = 0,
        tripsRemoved = 0,
        costImpact = 0,
        vehTypeChanged = 0,
        dealerChangedTrips = 0,
        vfrImapach = 0,
        vfrBefore = 0,
        vfrAfter = 0;
      let costBefore = 0,
        costAfter = 0;
      let distanceBefore = 0,
        distanceAfter = 0;
      data.map((item) => {
        if (item.trip_modified == 1) {
          tripsModified += 1;
        }
        if (item.trip_deleted == 1) {
          tripsRemoved += 1;
        }
        if (item.cost_deviation) {
          costImpact += item.cost_deviation;
        }

        if (item.vehicle_type_changed == 1) {
          vehTypeChanged += item.vehicle_type_changed;
        }
        if (item.customers_changed == 1) {
          dealerChangedTrips += item.customers_changed;
        }
        if (item.vfr_deviation) {
          vfrImapach += Math.abs(item.vfr_deviation);
        }

        if (item.vfr_original) {
          vfrBefore += item.vfr_original;
        }

        if (item.vfr_current) {
          vfrAfter += item.vfr_current;
        }

        if (item.cost_original) {
          costBefore += item.cost_original;
        }

        if (item.cost_current) {
          costAfter += item.cost_current;
        }

        if (item.round_trip_distance_current) {
          distanceAfter += item.round_trip_distance_current;
        }
        if (item.round_trip_distance_original) {
          distanceBefore += item.round_trip_distance_original;
        }
      });
      setTripData(data);
      setRowData(data);
      if (data.length) {
        vfrImapach = (vfrImapach / data.length).toFixed(2);
        vfrAfter = vfrAfter / data.length;
        vfrBefore = vfrBefore / data.length;
        vfrImapach = Math.abs(vfrAfter - vfrBefore).toFixed(2);
        costImpact = Math.abs(costAfter - costBefore).toFixed(0);
      }
      if (response.status === 200) {
        let distance = Math.abs(distanceAfter - distanceBefore).toFixed(0);
        setTotalTripsPlanned(data.length);
        setTotalTripsModified(tripsModified);
        setTotalTripsRemoved(tripsRemoved);
        setTotalCostImpact({
          value: costImpact,
          lable: costAfter - costBefore > 0,
        });
        setvehTypeChanges(vehTypeChanged);
        setdealerChangedTrips(dealerChangedTrips);
        setvfrImapach({
          value: vfrImapach,
          lable: vfrAfter - vfrBefore > 0,
        });
        setDistanceImpact({
          value: distance,
          lable: distanceAfter - distanceBefore > 0,
        });
      }
      setLoadShow("show-n");
      setOverlayBlock("show-n");
    });
  };

  const dateOptions = [
    { value: "orderDate", label: "Plan Creation Date" },
    { value: "invoiceDate", label: "Invoicing Date" },
  ];
  let colDefs = [
    {
      headerName: "",
      pinned: "left",
      headerClass: ["cellstylegridGW", "cellTopHeader"],
      marryChildren: true,
      children: [
        {
          headerName: "Plant Code",
          field: "plant_code",
          colId: "plant_code",
          width: "150",
          hide: true,
          valueGetter: (params) => {
            if (params.data.plant_code) {
              return params.data.plant_code;
            } else if (params.data.plant_code) {
              return params.data.plant_code;
            }
          },
        },
        {
          headerName: "Plant Name",
          pinned: "left",
          field: "plant_name",
          colId: "plant_name",
          width: "130",
          valueGetter: (params) => {
            if (params.data.plant_name) {
              return params.data.plant_name;
            } else {
              return "N/A";
            }
          },
        },
      ],
    },
    {
      headerName: "",
      headerClass: ["cellstylegridGW", "cellTopHeader"],
      pinned: "left",
      marryChildren: true,
      children: [
        {
          headerName: "Plan Date",
          field: "plan_creation_date",
          colId: "plan_creation_date",
          pinned: "left",
          width: "115",
          valueGetter: (params) => {
            if (params.data.plan_creation_date) {
              return getHyphenDDMMYYYY(params.data.plan_creation_date);
            } else {
              return "N/A";
            }
          },
        },
      ],
    },
    {
      headerName: "",
      headerClass: ["cellstylegridGW", "cellTopHeader"],
      pinned: "left",
      marryChildren: true,
      children: [
        {
          headerName: "Modified Type",
          field: "plant_name",
          colId: "plant_name",
          pinned: "left",
          width: "135",
          valueGetter: (params) => {
            if (params.data.trip_modified == 1) {
              return "Trip Edited";
            } else if (params.data.trip_deleted == 1) {
              return "Trip Deleted";
            } else {
              return "-";
            }
          },
        },
      ],
    },
    {
      headerName: "",
      headerClass: ["cellstylegridGW", "cellTopHeader"],
      marryChildren: true,
      children: [
        {
          headerName: "Transaction ID",
          field: "transaction_id",
          colId: "transaction_id",
          width: "190",
          cellRendererSelector: function (params) {
            let rendComponent = {
              component: "TransactionBtn",
            };
            return rendComponent;
          },
        },
      ],
    },
    {
      headerName: "",
      headerClass: ["cellstylegridGW", "cellTopHeader"],
      marryChildren: true,
      children: [
        {
          headerName: "Trip No",
          field: "trip_no",
          colId: "trip_no",
          width: "100",
          valueGetter: (params) => {
            if (params.data.trip_no) {
              return params.data.trip_no;
            } else {
              return "N/A";
            }
          },
        },
      ],
    },
    {
      headerName: "Plan Type",
      headerClass: [
        "cellstylegridGW",
        "cellTopHeader",
        "text-center",
        "cellstylegrid",
      ],
      cellClass: [
        "cellTopHeaderTemplate16",
        "cellstylegrid",
        "cpointer",
        "cellstylegrid",
      ],
      marryChildren: true,
      children: [
        {
          headerName: "Before",
          field: "original_vehicle_type",
          colId: "original_vehicle_type",
          width: "130",
          headerClass: ["cellstylegrid"],
          cellClass: ["cellstylegrid"],
          valueGetter: (params) => {
            if (params.data.original_vehicle_type) {
              // return params.data.original_vehicle_type;
              return (
                params.data.original_vehicle_type.charAt(0).toUpperCase() +
                params.data.original_vehicle_type.slice(1)
              );
            } else {
              return "N/A";
            }
          },
        },
        {
          headerName: "After",
          field: "current_vehicle_type",
          colId: "current_vehicle_type",
          width: "130",
          headerClass: ["cellstylegrid"],
          cellClass: (params) => {
            if (
              params.data.current_vehicle_type &&
              params.data.original_vehicle_type
            ) {
              if (
                params.data.current_vehicle_type !=
                params.data.original_vehicle_type
              ) {
                return "cellstylegridNeg";
              }
              return "cellstylegrid";
            } else {
              return "cellstylegridNeg";
            }
          },
          valueGetter: (params) => {
            if (params.data.current_vehicle_type) {
              // return params.data.current_vehicle_type;
              return (
                params.data.current_vehicle_type.charAt(0).toUpperCase() +
                params.data.current_vehicle_type.slice(1)
              );
            } else {
              return "N/A";
            }
          },
        },
      ],
    },
    {
      headerName: "Invoice Date",
      headerClass: [
        "cellstylegridGW",
        "cellTopHeader",
        "text-center",
        "cellstylegridG",
      ],
      cellClass: [
        "cellTopHeaderTemplate16",
        "cellstylegridG",
        "cpointer",
        "cellstylegridG",
      ],
      marryChildren: true,
      children: [
        {
          headerName: "Before",
          field: "invoicing_date_original",
          colId: "invoicing_date_original",
          width: "130",
          headerClass: ["cellstylegridG"],
          cellClass: ["cellstylegridG"],
          valueGetter: (params) => {
            if (params.data.invoicing_date_original) {
              return getHyphenDDMMYYYY(params.data.invoicing_date_original);
            } else {
              return "N/A";
            }
          },
        },
        {
          headerName: "After",
          field: "invoicing_date_current",
          colId: "invoicing_date_current",
          width: "130",
          headerClass: ["cellstylegridG"],
          cellClass: (params) => {
            if (
              params.data.invoicing_date_current &&
              params.data.invoicing_date_original
            ) {
              if (
                params.data.invoicing_date_current !=
                params.data.invoicing_date_original
              ) {
                return "cellstylegridNeg";
              }
              return "cellstylegridG";
            } else {
              return "cellstylegridNeg";
            }
          },
          valueGetter: (params) => {
            if (params.data.invoicing_date_current) {
              return getHyphenDDMMYYYY(params.data.invoicing_date_current);
            } else {
              return "N/A";
            }
          },
        },
      ],
    },

    {
      headerName: "Vehicle",
      headerClass: [
        "cellstylegridGW",
        "cellTopHeader",
        "text-center",
        "cellstylegrid",
      ],
      marryChildren: true,
      children: [
        {
          headerName: "Before",
          field: "vehicle_no_original",
          colId: "vehicle_no_original",
          width: "150",
          headerClass: ["cellstylegrid"],
          cellClass: ["cellstylegrid"],
          valueGetter: (params) => {
            if (params.data.vehicle_no_original) {
              return params.data.vehicle_no_original;
            } else {
              return "N/A";
            }
          },
        },
        {
          headerName: "After",
          field: "vehicle_no_current",
          colId: "vehicle_no_current",
          width: "150",
          headerClass: ["cellstylegrid"],
          cellClass: ["cellstylegrid"],

          cellClass: (params) => {
            if (
              params.data.vehicle_no_current &&
              params.data.vehicle_no_original
            ) {
              if (
                params.data.vehicle_no_current !=
                params.data.vehicle_no_original
              ) {
                return "cellstylegridNeg";
              }
              return "cellstylegrid";
            } else {
              return "cellstylegridNeg";
            }
          },
          valueGetter: (params) => {
            if (params.data.vehicle_no_current) {
              return params.data.vehicle_no_current;
            } else {
              return "N/A";
            }
          },
        },
      ],
    },
    {
      headerName: "Vehicle Type",
      headerClass: [
        "cellstylegridGW",
        "cellTopHeader",
        "text-center",
        "cellstylegridG",
      ],
      marryChildren: true,
      children: [
        {
          headerName: "Before",
          field: "vehicle_type_original",
          colId: "vehicle_type_original",
          width: "150",
          headerClass: ["cellstylegridG"],
          cellClass: ["cellstylegridG"],
          valueGetter: (params) => {
            if (params.data.vehicle_type_original) {
              return params.data.vehicle_type_original;
            } else {
              return "N/A";
            }
          },
        },
        {
          headerName: "After",
          field: "vehicle_type_current",
          colId: "vehicle_type_current",
          width: "150",
          headerClass: ["cellstylegridG"],
          cellClass: ["cellstylegridG"],

          cellClass: (params) => {
            if (
              params.data.vehicle_type_current &&
              params.data.vehicle_type_original
            ) {
              if (
                params.data.vehicle_type_current !=
                params.data.vehicle_type_original
              ) {
                return "cellstylegridNeg";
              }
              return "cellstylegridG";
            } else {
              return "cellstylegridNeg";
            }
          },
          valueGetter: (params) => {
            if (params.data.vehicle_type_current) {
              return params.data.vehicle_type_current;
            } else {
              return "N/A";
            }
          },
        },
      ],
    },
    {
      headerName: "VFR %",
      headerClass: [
        "cellstylegridGW",
        "cellTopHeader",
        "text-center",
        "cellstylegrid",
      ],
      cellClass: [
        "cellTopHeaderTemplate16",
        "cellstylegridG",
        "cpointer",
        "cellstylegrid",
      ],
      marryChildren: true,
      children: [
        {
          headerName: "Before",
          field: "vfr_original",
          colId: "vfr_original",
          width: "100",
          headerClass: ["cellstylegrid"],
          cellClass: ["cellstylegrid"],
          valueGetter: (params) => {
            if (params.data.vfr_original) {
              return params.data.vfr_original;
            } else {
              return 0;
            }
          },
        },
        {
          headerName: "After",
          field: "vfr_current",
          colId: "vfr_current",
          width: "100",
          headerClass: ["cellstylegrid"],
          cellClass: ["cellstylegrid"],
          cellClass: (params) => {
            if (params.data.vfr_current) {
              if (params.data.vfr_current > 100) {
                return "cellstylegridNeg";
              }
              return "cellstylegrid";
            } else {
              return "cellstylegrid";
            }
          },
          valueGetter: (params) => {
            if (params.data.vfr_current) {
              return params.data.vfr_current;
            } else {
              return 0;
            }
          },
        },
        {
          headerName: "Change",
          field: "vfr_deviation",
          colId: "vfr_deviation",
          width: "100",
          headerClass: ["cellstylegrid"],
          cellClass: ["cellstylegrid"],
          cellClass: (params) => {
            if (params.data.vfr_deviation) {
              if (params.data.vfr_deviation < 0) {
                return "cellstylegridNeg";
              } else if (params.data.vfr_deviation > 0) {
                return "cellstylegridPostve";
              }
              return "cellstylegrid";
            } else {
              return "cellstylegrid";
            }
          },
          valueGetter: (params) => {
            if (params.data.vfr_deviation) {
              if (params.data.vfr_deviation < 0) {
                return "(" + Math.abs(params.data.vfr_deviation) + ")";
              }
              return params.data.vfr_deviation;
            } else {
              return 0;
            }
          },
        },
      ],
    },

    {
      headerName: "Distance (KM)",
      headerClass: [
        "cellstylegridGW",
        "cellTopHeader",
        "text-center",
        "cellstylegridG",
      ],
      marryChildren: true,
      children: [
        {
          headerName: "Before",
          field: "round_trip_distance_original",
          colId: "round_trip_distance_original",
          width: "100",

          headerClass: ["cellstylegridG"],
          cellClass: ["cellstylegridG"],
          valueGetter: (params) => {
            if (params.data.round_trip_distance_original) {
              return params.data.round_trip_distance_original;
            } else {
              return 0;
            }
          },
        },
        {
          headerName: "After",
          field: "round_trip_distance_current",
          colId: "round_trip_distance_current",
          width: "100",
          headerClass: ["cellstylegridG"],
          cellClass: ["cellstylegridG"],
          valueGetter: (params) => {
            if (params.data.round_trip_distance_current) {
              return params.data.round_trip_distance_current;
            } else {
              return 0;
            }
          },
        },
        {
          headerName: "Change",
          field: "distance_deviation",
          colId: "distance_deviation",
          width: "100",
          headerClass: ["cellstylegridG"],
          cellClass: (params) => {
            if (params.data.distance_deviation) {
              if (params.data.distance_deviation > 0) {
                return "cellstylegridNeg";
              } else if (params.data.distance_deviation < 0) {
                return "cellstylegridPostve";
              }
              return "cellstylegridG";
            } else {
              return "cellstylegridG";
            }
          },
          valueGetter: (params) => {
            if (params.data.distance_deviation) {
              if (params.data.distance_deviation < 0) {
                return "(" + Math.abs(params.data.distance_deviation) + ")";
              }
              return params.data.distance_deviation;
            } else {
              return 0;
            }
          },
        },
      ],
    },
    {
      headerName: "Cost (Rs)",
      headerClass: [
        "cellstylegridGW",
        "cellTopHeader",
        "text-center",
        "cellstylegrid",
      ],
      marryChildren: true,
      children: [
        {
          headerName: "Before",
          field: "cost_original",
          colId: "cost_original",
          width: "130",

          headerClass: ["cellstylegrid"],
          cellClass: ["cellstylegrid"],
          valueGetter: (params) => {
            if (params.data.cost_original) {
              return formatRupees(params.data.cost_original);
            } else {
              return "₹0";
            }
          },
        },
        {
          headerName: "After",
          field: "cost_current",
          colId: "cost_current",
          width: "130",
          headerClass: ["cellstylegrid"],
          cellClass: ["cellstylegrid"],
          valueGetter: (params) => {
            if (params.data.cost_current) {
              return formatRupees(params.data.cost_current);
            } else {
              return "₹0";
            }
          },
        },
        {
          headerName: "Change",
          field: "cost_deviation",
          colId: "cost_deviation",
          width: "130",
          headerClass: ["cellstylegrid"],
          cellClass: ["cellstylegrid"],

          cellClass: (params) => {
            if (params.data.cost_deviation) {
              if (params.data.cost_deviation > 0) {
                return "cellstylegridNeg";
              } else if (params.data.cost_deviation < 0) {
                return "cellstylegridPostve";
              }
              return "cellstylegrid";
            } else {
              return "cellstylegrid";
            }
          },

          valueGetter: (params) => {
            if (params.data.cost_deviation) {
              if (params.data.cost_deviation < 0) {
                return (
                  "(" + formatRupees(Math.abs(params.data.cost_deviation)) + ")"
                );
              }
              return formatRupees(params.data.cost_deviation);
              // return params.data.cost_deviation;
            } else {
              return "₹0";
            }
          },
        },
      ],
    },
    {
      headerName: "Cost Per Case (Rs)",
      headerClass: [
        "cellstylegridGW",
        "cellTopHeader",
        "text-center",
        "cellstylegridG",
      ],
      marryChildren: true,
      children: [
        {
          headerName: "Before",
          field: "cost_per_case_original",
          colId: "cost_per_case_original",
          width: "100",
          headerClass: ["cellstylegridG"],
          cellClass: ["cellstylegridG"],
          valueGetter: (params) => {
            if (params.data.cost_per_case_original) {
              return formatRupees(params.data.cost_per_case_original);
            } else {
              return "₹0";
            }
          },
        },
        {
          headerName: "After",
          field: "cost_per_case_current",
          colId: "cost_per_case_current",
          width: "100",
          headerClass: ["cellstylegridG"],
          cellClass: ["cellstylegridG"],
          valueGetter: (params) => {
            if (params.data.cost_per_case_current) {
              return formatRupees(params.data.cost_per_case_current);
            } else {
              return "₹0";
            }
          },
        },
        {
          headerName: "Change",
          field: "cost_per_case_deviation",
          colId: "cost_per_case_deviation",
          width: "130",
          headerClass: ["cellstylegridG"],
          cellClass: ["cellstylegridG"],
          cellClass: (params) => {
            if (params.data.cost_per_case_deviation) {
              if (params.data.cost_per_case_deviation > 0) {
                return "cellstylegridNeg";
              } else if (params.data.cost_per_case_deviation < 0) {
                return "cellstylegridPostve";
              }
              return "cellstylegridG";
            } else {
              return "cellstylegridG";
            }
          },
          valueGetter: (params) => {
            if (params.data.cost_per_case_deviation) {
              if (params.data.cost_per_case_deviation < 0) {
                return (
                  "(" +
                  formatRupees(Math.abs(params.data.cost_per_case_deviation)) +
                  ")"
                );
              }
              return formatRupees(params.data.cost_per_case_deviation);
              return params.data.cost_per_case_deviation;
            } else {
              return "₹0";
            }
          },
        },
      ],
    },
    {
      headerName: "Weight (KG)",
      headerClass: [
        "cellstylegridGW",
        "cellTopHeader",
        "text-center",
        "cellstylegrid",
      ],
      cellClass: [
        "cellTopHeaderTemplate16",
        "cellstylegrid",
        "cpointer",
        "cellstylegrid",
      ],
      marryChildren: true,
      children: [
        {
          headerName: "Before",
          field: "weight_original",
          colId: "weight_original",
          width: "100",
          headerClass: ["cellstylegrid"],
          cellClass: ["cellstylegrid"],
          valueGetter: (params) => {
            if (params.data.weight_original) {
              return params.data.weight_original;
            } else {
              return 0;
            }
          },
        },
        {
          headerName: "After",
          field: "weight_current",
          colId: "weight_current",
          width: "100",
          headerClass: ["cellstylegrid"],
          cellClass: ["cellstylegrid"],
          valueGetter: (params) => {
            if (params.data.weight_current) {
              return params.data.weight_current;
            } else {
              return 0;
            }
          },
        },
        {
          headerName: "Change",
          field: "weight_deviation",
          colId: "weight_deviation",
          width: "100",
          headerClass: ["cellstylegrid"],
          cellClass: ["cellstylegrid"],
          cellClass: (params) => {
            if (params.data.weight_deviation) {
              if (params.data.weight_deviation < 0) {
                return "cellstylegridNeg";
              } else if (params.data.weight_deviation > 0) {
                return "cellstylegridPostve";
              }
              return "cellstylegrid";
            } else {
              return "cellstylegrid";
            }
          },
          valueGetter: (params) => {
            if (params.data.weight_deviation) {
              if (params.data.weight_deviation < 0) {
                return "(" + Math.abs(params.data.weight_deviation) + ")";
              }
              return params.data.weight_deviation;
            } else {
              return 0;
            }
          },
        },
      ],
    },
    {
      headerName: "Volume (M³)",
      headerClass: [
        "cellstylegridGW",
        "cellTopHeader",
        "text-center",
        "cellstylegridG",
      ],
      cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
      marryChildren: true,
      children: [
        {
          headerName: "Before",
          field: "volume_original",
          colId: "volume_original",
          width: "100",
          headerClass: ["cellstylegridG"],
          cellClass: ["cellstylegridG"],
          valueGetter: (params) => {
            if (params.data.volume_original) {
              return params.data.volume_original;
            } else {
              return 0;
            }
          },
        },
        {
          headerName: "After",
          field: "volume_current",
          colId: "volume_current",
          width: "100",
          headerClass: ["cellstylegridG"],
          cellClass: ["cellstylegridG"],
          valueGetter: (params) => {
            if (params.data.volume_current) {
              return params.data.volume_current;
            } else {
              return 0;
            }
          },
        },
        {
          headerName: "Change",
          field: "volume_deviation",
          colId: "volume_deviation",
          width: "100",
          headerClass: ["cellstylegridG"],
          cellClass: ["cellstylegridG"],

          cellClass: (params) => {
            if (params.data.volume_deviation) {
              if (params.data.volume_deviation < 0) {
                return "cellstylegridNeg";
              } else if (params.data.volume_deviation > 0) {
                return "cellstylegridPostve";
              }
              return "cellstylegridG";
            } else {
              return "cellstylegridG";
            }
          },
          valueGetter: (params) => {
            if (params.data.volume_deviation) {
              if (params.data.volume_deviation < 0) {
                return "(" + Math.abs(params.data.volume_deviation) + ")";
              }
              return params.data.volume_deviation;
            } else {
              return 0;
            }
          },
        },
      ],
    },
    {
      headerName: "Orders",
      headerClass: [
        "cellstylegridGW",
        "cellTopHeader",
        "text-center",
        "cellstylegrid",
      ],
      marryChildren: true,
      children: [
        {
          headerName: "Before",
          field: "no_of_orders_original",
          colId: "no_of_orders_original",
          width: "100",
          headerClass: ["cellstylegrid"],
          cellClass: ["cellstylegrid"],
          valueGetter: (params) => {
            if (params.data.no_of_orders_original) {
              return params.data.no_of_orders_original;
            } else {
              return 0;
            }
          },
        },
        {
          headerName: "After",
          field: "no_of_orders_current",
          colId: "no_of_orders_current",
          width: "100",
          headerClass: ["cellstylegrid"],
          cellClass: ["cellstylegrid"],
          valueGetter: (params) => {
            if (params.data.no_of_orders_current) {
              return params.data.no_of_orders_current;
            } else {
              return 0;
            }
          },
        },
        {
          headerName: "Change",
          field: "orders_deviation",
          colId: "orders_deviation",
          width: "100",
          headerClass: ["cellstylegrid"],
          cellClass: ["cellstylegrid"],

          cellClass: (params) => {
            if (params.data.orders_deviation) {
              if (params.data.orders_deviation < 0) {
                return "cellstylegridNeg";
              } else if (params.data.orders_deviation > 0) {
                return "cellstylegridPostve";
              }
              return "cellstylegrid";
            } else {
              return "cellstylegrid";
            }
          },
          valueGetter: (params) => {
            if (params.data.orders_deviation) {
              if (params.data.orders_deviation < 0) {
                return "(" + Math.abs(params.data.orders_deviation) + ")";
              }
              return params.data.orders_deviation;
            } else {
              return 0;
            }
          },
        },
        {
          headerName: "Added",
          field: "orders_added",
          colId: "orders_added",
          width: "100",
          headerClass: ["cellstylegrid"],
          cellClass: ["cellstylegrid"],
          valueGetter: (params) => {
            if (params.data.orders_added) {
              return params.data.orders_added;
            } else {
              return 0;
            }
          },
        },
        {
          headerName: "Removed",
          field: "orders_removed",
          colId: "orders_removed",
          width: "120",
          headerClass: ["cellstylegrid"],
          cellClass: ["cellstylegrid"],
          valueGetter: (params) => {
            if (params.data.orders_removed) {
              return params.data.orders_removed;
            } else {
              return 0;
            }
          },
        },
        {
          headerName: "New Orders Post Planning",
          headerTooltip: "New Orders Post Planning",
          field: "newly_added_orders_post_planning",
          colId: "newly_added_orders_post_planning",
          width: "200",
          headerClass: ["cellstylegrid"],
          cellClass: ["cellstylegrid"],
          valueGetter: (params) => {
            if (params.data.newly_added_orders_post_planning) {
              return params.data.newly_added_orders_post_planning;
            } else {
              return 0;
            }
          },
        },
      ],
    },
    {
      headerName: "Customers",
      headerClass: [
        "cellstylegridGW",
        "cellTopHeader",
        "text-center",
        "cellstylegridG",
      ],
      marryChildren: true,
      children: [
        {
          headerName: "Before",
          field: "no_of_customers_original",
          colId: "no_of_customers_original",
          width: "100",
          headerClass: ["cellstylegridG"],
          cellClass: ["cellstylegridG"],
          valueGetter: (params) => {
            if (params.data.no_of_customers_original) {
              return params.data.no_of_customers_original;
            } else {
              return 0;
            }
          },
        },
        {
          headerName: "After",
          field: "no_of_customers_current",
          colId: "no_of_customers_current",
          width: "100",
          headerClass: ["cellstylegridG"],
          cellClass: ["cellstylegridG"],
          valueGetter: (params) => {
            if (params.data.no_of_customers_current) {
              return params.data.no_of_customers_current;
            } else {
              return 0;
            }
          },
        },
        {
          headerName: "Change",
          field: "customers_deviation",
          colId: "customers_deviation",
          width: "100",
          headerClass: ["cellstylegridG"],
          cellClass: ["cellstylegridG"],

          cellClass: (params) => {
            if (params.data.customers_deviation) {
              if (params.data.customers_deviation < 0) {
                return "cellstylegridNeg";
              } else if (params.data.customers_deviation > 0) {
                return "cellstylegridPostve";
              }
              return "cellstylegridG";
            } else {
              return "cellstylegridG";
            }
          },
          valueGetter: (params) => {
            if (params.data.customers_deviation) {
              if (params.data.customers_deviation < 0) {
                return "(" + Math.abs(params.data.customers_deviation) + ")";
              }
              return params.data.customers_deviation;
            } else {
              return 0;
            }
          },
        },
        {
          headerName: "Added",
          field: "customers_added",
          colId: "customers_added",
          width: "90",
          headerClass: ["cellstylegridG"],
          cellClass: ["cellstylegridG"],
          valueGetter: (params) => {
            if (params.data.customers_added) {
              return params.data.customers_added;
            } else {
              return 0;
            }
          },
        },
        {
          headerName: "Removed",
          field: "customers_removed",
          colId: "customers_removed",
          width: "115",
          headerClass: ["cellstylegridG"],
          cellClass: ["cellstylegridG"],
          valueGetter: (params) => {
            if (params.data.customers_removed) {
              return params.data.customers_removed;
            } else {
              return 0;
            }
          },
        },
      ],
    },
    {
      headerName: "Modification Remarks",
      headerClass: [
        "cellstylegridGW",
        "cellTopHeader",
        "text-center",
        "cellstylegrid",
      ],
      marryChildren: true,
      children: [
        {
          headerName: "Remarks",
          field: "modification_remarks",
          colId: "modification_remarks",
          width: "300",
          headerClass: ["cellstylegrid"],
          cellClass: ["cellstylegrid"],
          valueGetter: (params) => {
            if (
              params.data.modification_remarks &&
              params.data.modification_remarks === "discarded_obd_removal"
            ) {
              return "OBD Discarded";
            } else if (params.data.modification_remarks) {
              return params.data.modification_remarks;
            } else {
              return "N/A";
            }
          },
        },
        {
          headerName: "Sub Remarkds",
          field: "modification_sub_reasons",
          colId: "modification_sub_reasons",
          width: "250",
          headerClass: ["cellstylegrid"],
          cellClass: ["cellstylegrid"],
          valueGetter: (params) => {
            if (
              params.data.modification_sub_reasons &&
              params.data.modification_sub_reasons === "discarded_obd_removal"
            ) {
              return "OBD Discarded";
            } else if (
              params.data.modification_sub_reasons &&
              params.data.modification_sub_reasons ===
                "discarded_obd_maintenance"
            ) {
              return "OBD Discarded";
            } else if (params.data.modification_sub_reasons) {
              return params.data.modification_sub_reasons;
            } else {
              return "N/A";
            }
          },
        },
      ],
    },
  ];
  const boundTypeLocations = async (param) => {
    var location_access = JSON.parse(localStorage.getItem("location_access"));
    var locationNames = [{ label: "All", value: "All" }];
    var countries = [{ label: "All", value: "All" }];
    var locationZones = [{ label: "All", value: "All" }];
    var regions = [{ label: "All", value: "All" }];
    var countriesSet = new Set();
    var locationZoneSet = new Set();
    var regionSet = new Set();
    var locationNameSet = new Set();
    await redirectURL
      .post("master/getBoundTypeLocations", param)
      .then((response) => {
        if (response.data.status === "success") {
          let boundLocations = response.data.boundLocations;
          if (boundLocations.length) {
            setboundLocations(boundLocations);
          }
          let pickupLocationCodes = {};
          boundLocations.map((item) => {
            pickupLocationCodes[item.location_code] = item.location_name;
            return true;
          });
          if (boundLocations.length > 0) {
            boundLocations.map((item) => {
              if (location_access != null) {
                if (location_access.includes(item.location_code)) {
                  locationNames.push({
                    value: item.location_code,
                    label: item.location_name,
                  });

                  if (
                    item.country &&
                    item.country !== null &&
                    !countriesSet.has(item.country)
                  ) {
                    // console.log("first loop", item.country);
                    countries.push({
                      value: item.country.toUpperCase(),
                      label: item.country.toUpperCase(),
                    });
                    countriesSet.add(item.country.toUpperCase());
                  }
                  if (
                    item.region &&
                    item.region !== null &&
                    !regionSet.has(item.region)
                  ) {
                    regions.push({
                      value: item.region.toUpperCase(),
                      label: item.region.toUpperCase(),
                    });
                    regionSet.add(item.region.toUpperCase());
                  }
                  if (
                    item.location_zone &&
                    item.location_zone !== null &&
                    !locationZoneSet.has(item.location_zone)
                  ) {
                    locationZones.push({
                      value: item.location_zone.toUpperCase(),
                      label: item.location_zone.toUpperCase(),
                    });
                    locationZoneSet.add(item.location_zone.toUpperCase());
                  }
                }
              } else {
                if (
                  item.country &&
                  item.country !== null &&
                  !countriesSet.has(item.country)
                ) {
                  countries.push({
                    value: item.country.toUpperCase(),
                    label: item.country.toUpperCase(),
                  });
                  countriesSet.add(item.country.toUpperCase());
                }
                if (
                  item.region &&
                  item.region !== null &&
                  !regionSet.has(item.region)
                ) {
                  regions.push({
                    value: item.region.toUpperCase(),
                    label: item.region.toUpperCase(),
                  });
                  regionSet.add(item.region.toUpperCase());
                }
                if (
                  item.location_zone &&
                  item.location_zone !== null &&
                  !locationZoneSet.has(item.location_zone)
                ) {
                  locationZones.push({
                    value: item.location_zone,
                    label: item.location_zone.toUpperCase(),
                  });
                  locationZoneSet.add(item.location_zone);
                }
                if (
                  item.location_code &&
                  item.location_code !== null &&
                  item.location_name !== null &&
                  !locationNameSet.has(item.location_code)
                ) {
                  locationNames.push({
                    value: item.location_code.toUpperCase(),
                    label: item.location_name.toUpperCase(),
                  });
                  locationNameSet.add(item.location_code.toUpperCase());
                }
              }
              return true;
            });
            setZoneOptions(locationZones);
            // setlocationOptions(locationNames);
          }
        }
      });
  };

  const formatIndianCurrency = (value) => {
    const valueStr = value.toString();
    // Return 0 directly without formatting
    if (valueStr === "0") {
      return "0";
    }
    const lastThree = valueStr.slice(-3);
    const otherNumbers = valueStr.slice(0, -3);
    const formatted =
      otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + lastThree;
    return `${formatted}`;
    // return `₹${formatted}`;
  };
  const onChangeDC = (e) => {
    setPlant(e);
    let zone = boundLocations.filter((item) => item.location_code === e.value);
    zone = zone[0].location_zone.toUpperCase();
    setZone({ value: zone, label: zone });
  };
  let pickupOptions = [{ value: "", label: "All" }],
    pickupCodes = [];
  let allLocs = boundLocations;
  if (zone.value !== "" && zone.value !== "All") {
    allLocs = boundLocations.filter(
      (item) => item.location_zone === zone.value
    );
  }
  allLocs.map((item) => {
    if (!pickupCodes.includes(item.location_code)) {
      pickupOptions.push({
        value: item.location_code,
        label: item.location_name,
      });
      pickupCodes.push(item.location_code);
    }
  });

  const onClickCounters = (counter) => {
    if (counter == "totalTrips") {
      setRowData(tripData);
    } else if (counter == "modifiedTrips") {
      let data = tripData.filter((item) => item.trip_modified == 1);
      setRowData(data);
    } else if (counter == "removedTrips") {
      let data = tripData.filter((item) => item.trip_deleted == 1);
      setRowData(data);
    } else if (counter == "vehTypeChanges") {
      let data = tripData.filter((item) => item.vehicle_type_changed == 1);
      setRowData(data);
    } else if (counter == "custmerChanged") {
      let data = tripData.filter((item) => item.customers_changed == 1);
      setRowData(data);
    }
  };

  const handleButtonClick = () => {
    setIsExpanded(!isExpanded);
    setIsVisible((prev) => !prev);
  };

  const categories = ["January", "February", "March"];

  let seriesData = [];
  let drilldownSeriesData = [];
  const groupedByDay = tripModification.reduce((acc, item) => {
    const day = item.day;
    if (!acc[day]) {
      acc[day] = [];
    }
    acc[day].push(item);
    return acc;
  }, {});
  let totalTripData = [],
    modifiedTripsData = [];
  Object.entries(groupedByDay).forEach(([key, items]) => {
    let dataArr = [];
    let totalTrips = 0;
    let modifiedTrips = 0;
    items.forEach((rec) => {
      dataArr.push([rec.plant_name, rec.total_trips]);
      totalTrips += rec.total_trips;
      modifiedTrips += rec.modified_trips;
    });
    totalTripData.push({
      name: key,
      y: totalTrips,
      drilldown: "totalTrips-" + key,
    });
    modifiedTripsData.push({
      name: key,
      y: modifiedTrips,
      drilldown: "modifiedTrips-" + key,
      percentage: parseFloat(((modifiedTrips / totalTrips) * 100).toFixed(1)),
    });
    let data = {
      id: "totalTrips-" + key,
      name: "Total Trips",
      data: dataArr,
    };
    drilldownSeriesData.push(data);
  });
  Object.entries(groupedByDay).forEach(([key, items]) => {
    let dataArr = [];
    items.forEach((rec) => {
      dataArr.push([rec.plant_name, rec.modified_trips]);
    });
    let data = {
      id: "modifiedTrips-" + key,
      name: "Modified Trips",
      data: dataArr,
    };
    drilldownSeriesData.push(data);
  });
  seriesData = [
    { name: "Total Trips", data: totalTripData },
    { name: "Modified Trips", data: modifiedTripsData },
  ];

  const costGroup = costData.reduce((acc, item) => {
    const day = item.day;
    if (!acc[day]) {
      acc[day] = [];
    }
    acc[day].push(item);
    return acc;
  }, {});
  let costSeries = [],
    costDrillDownSeries = [];
  Object.entries(costGroup).forEach(([key, items]) => {
    let totalCost = 0;
    let dataArr = [];
    items.map((rec) => {
      dataArr.push({ name: rec.plant_name, y: rec.cost_impact_rs });
      totalCost += rec.cost_impact_rs;
    });
    costDrillDownSeries.push({ id: key, name: key, data: dataArr });
    costSeries.push({
      id: key,
      name: key,
      y: parseFloat(totalCost.toFixed(1)),
      drilldown: key,
    });
  });

  const costPerCaseDataGrp = costPerCaseData.reduce((acc, item) => {
    const day = item.day;
    if (!acc[day]) {
      acc[day] = [];
    }
    acc[day].push(item);
    return acc;
  }, {});
  let costPerCaseSeries = [],
    costPerCaseDrillDownSeries = [];
  Object.entries(costPerCaseDataGrp).forEach(([key, items]) => {
    let totalCostPC = 0;
    let dataArr = [];
    items.map((rec) => {
      dataArr.push({ name: rec.plant_name, y: rec.cost_per_case_impact_rs });
      totalCostPC += rec.cost_per_case_impact_rs;
    });
    costPerCaseDrillDownSeries.push({ id: key, name: key, data: dataArr });
    totalCostPC = totalCostPC / items.length;
    costPerCaseSeries.push({
      id: key,
      name: key,
      y: parseFloat(totalCostPC.toFixed(2)),
      drilldown: key,
    });
  });
  const distanceImpactDataGrp = distanceImpactData.reduce((acc, item) => {
    const day = item.day;
    if (!acc[day]) {
      acc[day] = [];
    }
    acc[day].push(item);
    return acc;
  }, {});
  let distanceImpactSeries = [],
    distanceImapctDrillDown = [];
  Object.entries(distanceImpactDataGrp).forEach(([key, items]) => {
    let totalCostPC = 0;
    let dataArr = [];
    items.map((rec) => {
      dataArr.push({ name: rec.plant_name, y: rec.distance_impact_km });
      totalCostPC += rec.distance_impact_km;
    });
    distanceImapctDrillDown.push({ id: key, name: key, data: dataArr });
    distanceImpactSeries.push({
      id: key,
      name: key,
      y: parseFloat(totalCostPC.toFixed(1)),
      drilldown: key,
    });
  });

  const vfrImpactDataGrp = vfrImpactData.reduce((acc, item) => {
    const day = item.day;
    if (!acc[day]) {
      acc[day] = [];
    }
    acc[day].push(item);
    return acc;
  }, {});
  let vfrImpactSeries = [],
    vfrImpactDrillDown = [];
  Object.entries(vfrImpactDataGrp).forEach(([key, items]) => {
    let totalCostPC = 0;
    let dataArr = [];
    items.map((rec) => {
      dataArr.push({ name: rec.plant_name, y: rec.vfr_impact_percentage });
      totalCostPC += rec.vfr_impact_percentage;
    });
    totalCostPC = totalCostPC / items.length;
    vfrImpactDrillDown.push({ id: key, name: key, data: dataArr });
    vfrImpactSeries.push({
      id: key,
      name: key,
      y: parseFloat(totalCostPC.toFixed(1)),
      drilldown: key,
    });
  });
  vfrImpactSeries = vfrImpactSeries.map((item) => {
    dailyvfrImpactData.map((rec) => {
      if (rec.day === item.name) {
        item.y = parseFloat(rec.vfr_impact_percentage.toFixed(1));
      }
    });
    return item;
  });
  const onClickGetData = () => {
    setLoadShow("show-m");
    setOverlayBlock("show-m");

    if (fromDate > toDate) {
      setShow(true);
      setBasicTitle("From Date Should be Less than To Date...!");
      setBasicType("warning");
      return;
    }
    // Calculate the difference in days between the two dates
    const from = new Date(fromDate);
    const to = new Date(toDate);
    const timeDifference = to - from; // Difference in milliseconds
    const dayDifference = timeDifference / (1000 * 60 * 60 * 24); // Convert to days

    if (dayDifference > 30) {
      setShow(true);
      setBasicTitle(
        "The interval between From Date and To Date should not exceed 30 days!"
      );
      setBasicType("warning");
      return;
    }

    getPlanAnalysisData();
    getPlanAnalysisCharts();
  };
  return (
    <> 
    <div className="row">
      <div className="col-12 mt-1">
        <SweetAlert
          show={show}
          type={basicType}
          title={basicTitle}
          onConfirm={closeAlert}
        ></SweetAlert>
        <div
          className="col-12 row mt-0 mb-2 pt-0 pl-0 mb-1 d-flex justify-content-between"
          style={{ marginLeft: 4 }}
        >
          <h3 className="mt-0 mb-0 pt-0 pl-0">Plan Modification Analysis</h3>
        </div>
        <div className="row" style={{ width: "100%", marginLeft: 0 }}>
          <div className="" style={{ width: 200 }}>
            <label>Filter Type :</label>
            <Select
              styles={{ width: 200 }}
              value={filterType}
              onChange={(e) => setFilterType(e)}
              options={dateOptions}
            />
          </div>
          <div className="ml-3">
            <label>From Date :</label>
            <br />
            <input
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
              type="date"
              max={new Date().toISOString().split("T")[0]} // Set max to today's date
              className="custom-input"
            />
          </div>
          <div className="ml-3">
            <label>To Date :</label>
            <br />
            <input
              type="date"
              onChange={(e) => setToDate(e.target.value)} // Fixed setter for `toDate`
              value={toDate}
              max={new Date().toISOString().split("T")[0]} // Set max to today's date
              className="custom-input"
            />
          </div>

          <div className="ml-3" style={{ width: 200 }}>
            <label>Branch :</label>
            <Select
              options={zoneOptions}
              value={zone}
              onChange={(e) => setZone(e)}
            />
          </div>
          <div className="ml-3" style={{ width: 200 }}>
            <label>Plant/DC</label>
            <Select
              options={pickupOptions}
              value={plant}
              onChange={onChangeDC}
            />
          </div>
          <div className="ml-3" style={{ width: 100 }}>
            <button
              className="btn btn-warning"
              style={{ marginTop: 27, height: 35 }}
              onClick={() => {
                onClickGetData();
              }}
            >
              Get Data
            </button>
          </div>
          <div style={{ marginTop: 35 }} className="d-flex flex-row">
            <Switch
              checked={isVisible}
              onChange={handleButtonClick}
              onColor="#0341fc"
              offColor="#ccc"
              onHandleColor="#fff"
              offHandleColor="#fff"
              handleDiameter={18}
              uncheckedIcon={false}
              checkedIcon={false}
              height={20}
              width={48}
            />
            <div>
              <span
                style={{
                  marginLeft: 10,
                  fontSize: "16px",
                  fontWeight: "500",
                  paddingBottom: 3,
                }}
              >
                Dashboard
              </span>
            </div>
          </div>
        </div>

        <div className="mt-3p mb-0 row" style={{ marginTop: 9 }}>
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 beffect ">
            <div className="card">
              <div className="card-body">
                <div
                  className="crm-numbers pb-0"
                  style={{
                    margin: "0px !important",
                    padding: "0px !important",
                  }}
                >
                  <div
                    className="row"
                    style={{
                      margin: "0px",
                      padding: "0px",
                      alignSelf: "center",
                    }}
                  >
                    <div
                      className="col cursorPointer "
                      style={{
                        margin: "0px",
                        alignSelf: "center",
                        minWidth: "100px",
                      }}
                      onClick={() => {
                        onClickCounters("totalTrips");
                      }}
                    >
                      <span className="f12">
                        <i
                          className="fa fa-calendar-check-o f20"
                          aria-hidden="true"
                          style={{
                            fontSize: "20px",
                            color: "#0683cc",
                            marginBottom: "3px",
                          }}
                        ></i>
                        <br />

                        <span
                          style={{
                            fontWeight: 500,
                            color: "black",
                            fontSize: "14px",
                          }}
                        >
                          {" "}
                          Total Trips Planned{" "}
                        </span>
                      </span>
                      <h4
                        className="f17 "
                        style={{
                          margin: "0px",
                          padding: "0px",
                          color: "#0683cc",
                          marginTop: "2px",
                        }}
                      >
                        <span className="counter">{totalTripsPlanned}</span>
                      </h4>
                    </div>
                    <div
                      className="col cursorPointer "
                      style={{
                        margin: "0px",
                        alignSelf: "center",
                        minWidth: "100px",
                      }}
                      onClick={() => {
                        onClickCounters("modifiedTrips");
                      }}
                    >
                      <span className="f12">
                        <MdRebaseEdit style={{ fontSize: "24px" }} />
                        <br />

                        <span
                          style={{
                            fontWeight: 500,
                            color: "black",
                            fontSize: "14px",
                          }}
                        >
                          {" "}
                          Total Trips Modified
                        </span>
                      </span>
                      <h4 className="f17 ">
                        <span className="counter">{totalTripsModified}</span>
                      </h4>
                    </div>
                    <div
                      className="col cursorPointer"
                      style={{
                        margin: "0px",
                        alignSelf: "center",
                        minWidth: "65px",
                        marginTop: "6px",
                      }}
                      onClick={() => {
                        onClickCounters("removedTrips");
                      }}
                    >
                      <span className="f12">
                        <MdAutoDelete
                          style={{
                            fontSize: "24px",
                            color: "#dc5df5",
                            marginBottom: "1px",
                          }}
                        />
                        <br />

                        <span
                          style={{
                            fontWeight: 500,
                            color: "black",
                            fontSize: "14px",
                          }}
                        >
                          {" "}
                          Total Trips Removed
                        </span>
                      </span>
                      <h4
                        className="f17"
                        style={{ color: "#dc5df5", marginTop: "3px" }}
                      >
                        <span className="counter">{totalTripsRemoved}</span>
                      </h4>
                    </div>

                    <div
                      className="col cursorPointer"
                      style={{
                        margin: "0px",
                        alignSelf: "center",
                        minWidth: "85px",
                      }}
                    >
                      <div
                        className="d-flex justify-content-center align-items-end  float-right"
                        style={{ width: "15%" }}
                      >
                        <div>
                          <i
                            class="fa fa-info-circle info-icon"
                            aria-hidden="true"
                            data-tooltip="Total Distance (After) - Total Distance (Before) across the trips"
                          ></i>
                        </div>
                      </div>
                      <span className="f12">
                        <GiPathDistance
                          style={{ fontSize: "24px", color: "green" }}
                        />
                        <br />

                        <span
                          style={{
                            fontWeight: 500,
                            color: "black",
                            fontSize: "14px",
                          }}
                        >
                          Distance Impact (KM)
                        </span>
                      </span>
                      <h4 className="green f17" style={{ marginBottom: 3 }}>
                        <span className="counter">
                          {disnactImapact.value} &nbsp;&nbsp;
                          {disnactImapact?.value > 0 ? (
                            disnactImapact.lable ? (
                              <IoCaretUpSharp style={{ color: "#F92B2D" }} />
                            ) : (
                              <IoCaretDownSharp style={{ color: "green" }} />
                            )
                          ) : (
                            ""
                          )}
                        </span>
                      </h4>
                    </div>
                    <div
                      className="col cursorPointer"
                      style={{
                        margin: "0px",
                        alignSelf: "center",
                        minWidth: "95px",
                      }}
                    >
                      <div
                        className="d-flex justify-content-center align-items-end  float-right"
                        style={{ width: "15%" }}
                      >
                        <div>
                          <i
                            class="fa fa-info-circle info-icon"
                            aria-hidden="true"
                            data-tooltip="Total Cost (After) - Total Cost (Before) across the trips"
                          ></i>
                        </div>
                      </div>
                      <span className="f12">
                        <LuIndianRupee
                          style={{
                            fontSize: "24px",
                            color: "#0683cc",
                          }}
                        />
                        <br />

                        <span
                          style={{
                            fontWeight: 500,
                            color: "black",
                            fontSize: "14px",
                          }}
                        >
                          Total Cost Impact (Rs)
                        </span>
                      </span>
                      <h4 className="f17" style={{ color: "#0683cc" }}>
                        <span className="counter">
                          {formatIndianCurrency(totalCostImpact.value)}{" "}
                          &nbsp;&nbsp;
                          {totalCostImpact?.value > 0 ? (
                            totalCostImpact.label ? (
                              <IoCaretUpSharp style={{ color: "#F92B2D" }} />
                            ) : (
                              <IoCaretDownSharp style={{ color: "green" }} />
                            )
                          ) : (
                            ""
                          )}
                        </span>
                      </h4>
                    </div>
                    <div
                      className="col cursorPointer"
                      style={{
                        margin: "0px",
                        alignSelf: "center",
                        minWidth: "85px",
                      }}
                      onClick={() => {
                        onClickCounters("vehTypeChanges");
                      }}
                    >
                      <span className="f12">
                        <FaTruck style={{ fontSize: "24px", color: "green" }} />
                        <br />

                        <span
                          style={{
                            fontWeight: 500,
                            color: "black",
                            fontSize: "14px",
                          }}
                        >
                          Vehicle Type Changes
                        </span>
                      </span>
                      <h4 className="green f17" style={{ marginBottom: 3 }}>
                        <span className="counter">{vehTypeChanges}</span>
                      </h4>
                    </div>
                    <div
                      className="col cursorPointer "
                      style={{
                        margin: "0px",
                        alignSelf: "center",
                        minWidth: "100px",
                      }}
                    >
                      <div
                        className="d-flex justify-content-center align-items-end  float-right"
                        style={{ width: "15%" }}
                      >
                        <div>
                          <i
                            class="fa fa-info-circle info-icon"
                            aria-hidden="true"
                            data-tooltip="Avg. VFR (After) - Avg. VFR (Before) across the trips"
                          ></i>
                        </div>
                      </div>
                      <span className="f12">
                        <i
                          className="fa fa-cube f24"
                          style={{
                            fontSize: "24px",
                            color: "#dc5df5",
                            marginBottom: "1px",
                          }}
                        ></i>
                        <br />
                        <span
                          style={{
                            fontWeight: 500,
                            color: "black",
                            fontSize: "14px",
                          }}
                        >
                          {" "}
                          VFR Impact (%)
                        </span>
                      </span>
                      <h4
                        className="f17 "
                        style={{ marginTop: "3px", color: "#dc5df5" }}
                      >
                        <span className="counter">
                          {vfrImapach.value}
                          &nbsp;&nbsp;
                          {vfrImapach?.value > 0 ? (
                            vfrImapach.lable ? (
                              <IoCaretUpSharp style={{ color: "green" }} />
                            ) : (
                              <IoCaretDownSharp style={{ color: "#F92B2D" }} />
                            )
                          ) : (
                            ""
                          )}
                        </span>
                      </h4>
                    </div>
                    <div
                      className="col cursorPointer "
                      style={{
                        margin: "0px",
                        alignSelf: "center",
                        minWidth: "100px",
                      }}
                      onClick={() => {
                        onClickCounters("custmerChanged");
                      }}
                    >
                      <span className="f12">
                        <LiaUserEditSolid
                          style={{
                            fontSize: "24px",
                            marginBottom: "1px",
                            color: "txt-yellow",
                          }}
                        />
                        <br />

                        <span
                          style={{
                            fontWeight: 500,
                            color: "black",
                            fontSize: "14px",
                          }}
                        >
                          {" "}
                          Customers Changed
                        </span>
                      </span>
                      <h4 className="f17 ">
                        <span className="counter">{dealerChangedTrips}</span>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={`chart-container ${isVisible ? "visible" : "hidden"}`}>
          <div className="mt-3">
            <GroupColumnChartComponent
              title={"Trips Impact"}
              categories={categories}
              series={seriesData}
              drilldownSeries={drilldownSeriesData}
              xaxisTitle="Dates"
              yaxisTitle="Trips Count"
            />
          </div>

          <div className="row d-flex flex-row mt-3">
            <div className="col-6">
              <DrilldownChartComponent
                title={"Cost Impact (Rs)"}
                categories={categories}
                series={costSeries}
                drilldownSeries={costDrillDownSeries}
                xaxisTitle="Dates"
                yaxisTitle="Cost (Rs)"
                name={"Cost Impact"}
              />
            </div>

            <div className="col-6">
              <DrilldownChartComponent
                title={"Avg. Cost Per Case Impact (Rs)"}
                categories={categories}
                series={costPerCaseSeries}
                drilldownSeries={costPerCaseDrillDownSeries}
                xaxisTitle="Dates"
                yaxisTitle="Cost Per Case (Rs)"
                name={"Cost Per Case Impact"}
              />
            </div>
          </div>

          <div className="row d-flex flex-row mt-3">
            <div className="col-6 m-0">
              <DrilldownChartComponent
                title={"Distance Impact (KM)"}
                categories={categories}
                series={distanceImpactSeries}
                drilldownSeries={distanceImapctDrillDown}
                xaxisTitle="Dates"
                yaxisTitle="Distance (KM)"
                name={"Distance Impact"}
              />
            </div>

            <div className="col-6 m-0">
              <DrilldownChartComponent
                title={"Avg. VFR Impact (%)"}
                categories={categories}
                series={vfrImpactSeries}
                drilldownSeries={vfrImpactDrillDown}
                xaxisTitle="Dates"
                yaxisTitle="VFR %"
                name={"VFR Impact"}
              />
            </div>
          </div>
        </div>
        <div
          className="ag-theme-alpine"
          style={{ height: 400, width: "100%", marginTop: 20 }}
        >
          <MyAgGridComponent gridData={rowData} colDefs={colDefs} />
        </div>
        <div className={"loader " + loadshow}></div>
        <div
          className={"overlay-block " + overlayBlock}
          onClick={hideSlideBlock}
        ></div>
      </div></div>
    </>
  );
};

export default PlanAnalysis;
function formatRupees(value) {
  // Ensure the value is a number
  const num = Number(value);
  if (isNaN(num)) {
    return "₹0";
  }
  // Format the number with commas
  return `₹${num.toLocaleString("en-IN")}`;
}
