import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Drilldown from "highcharts/modules/drilldown";

// Initialize the drilldown module
Drilldown(Highcharts);

const GroupColumnChartComponent = ({
  title = "",
  subtitle = "",
  categories = [],
  xaxisTitle = "Delivery Date",
  yaxisTitle = "Quantity (EA)",
  series = [],
  drilldownSeries = [],
  noDataMessage = "No data to display",
}) => {
  const highchartoptions = {
    chart: {
      type: "column",
    },
    title: {
      text: title,
      align: "center",
    },
    subtitle: {
      align: "left",
      text: subtitle,
    },
    accessibility: {
      announceNewData: {
        enabled: true,
      },
    },
    xAxis: {
      type: "category",
      title: {
        text: xaxisTitle,
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: yaxisTitle,
      },
    },
    tooltip: {
      formatter: function () {
        // Customize the tooltip to include percentage
        return `<b>${this.series.name}</b><br/>${this.point.name}: ${this.y}${
          this.point.percentage ? ` (${this.point.percentage}%)` : ""
        }`;
      },
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
        grouping: true, // Add this line to enable grouping
      },
      series: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          // format: point.percentage?"{point.y} ({point.percentage})":"{point.y}",
          // format:
          //   "{point.y}{point.percentage ? ' (' + point.percentage + '%)' : ''}",
          // formatter: function () {
          //   // Check if 'percentage' exists and append it to the 'y' value
          //   return this.point.percentage
          //     ? `${this.y} (${this.point.percentage}%)`
          //     : `${this.y}`;
          // },
          formatter: function () {
            // Display y and percentage in the data labels
            return this.point.percentage
              ? `${this.y} (${this.point.percentage}%)`
              : `${this.y}`;
          },
        },
      },
    },
    series: series,
    drilldown: {
      series: drilldownSeries,
    },
    lang: {
      noData: noDataMessage,
    },
    noData: {
      style: {
        fontWeight: "bold",
        fontSize: "15px",
        color: "#303030",
      },
    },
    tooltip: {
      formatter: function () {
        // Customize the tooltip to include percentage
        return `<b>${this.series.name}</b><br/>${this.point.name}: ${this.y}${
          this.point.percentage ? ` (${this.point.percentage}%)` : ""
        }`;
      },
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
      series: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          // format: "{point.y}",
          formatter: function () {
            // Display y and percentage in the data labels
            return this.point.percentage
              ? `${this.y} (${this.point.percentage}%)`
              : `${this.y}`;
          },
        },
      },
    },
    series: series,
    drilldown: {
      series: drilldownSeries,
    },
    lang: {
      noData: noDataMessage,
    },
    noData: {
      style: {
        fontWeight: "bold",
        fontSize: "15px",
        color: "#303030",
      },
    },
    credits: {
      enabled: false,
    },
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <HighchartsReact
        highcharts={Highcharts}
        options={highchartoptions}
        containerProps={{ style: { width: "100%", height: "100%" } }}
      />
    </div>
  );
};

export default GroupColumnChartComponent;

// const drilldownSeries = [
//   {
//     id: "productA-jan",
//     name: "Product A - January",
//     data: [
//       ["Week 1", 10],
//       ["Week 2", 15],
//       ["Week 3", 25],
//     ],
//   },
//   {
//     id: "productA-feb",
//     name: "Product A - February",
//     data: [
//       ["Week 1", 20],
//       ["Week 2", 25],
//       ["Week 3", 15],
//     ],
//   },
//   {
//     id: "productA-mar",
//     name: "Product A - March",
//     data: [
//       ["Week 1", 30],
//       ["Week 2", 20],
//       ["Week 3", 20],
//     ],
//   },
//   {
//     id: "productB-jan",
//     name: "Product B - January",
//     data: [
//       ["Week 1", 20],
//       ["Week 2", 30],
//       ["Week 3", 30],
//     ],
//   },
//   {
//     id: "productB-feb",
//     name: "Product B - February",
//     data: [
//       ["Week 1", 40],
//       ["Week 2", 30],
//       ["Week 3", 20],
//     ],
//   },
//   {
//     id: "productB-mar",
//     name: "Product B - March",
//     data: [
//       ["Week 1", 50],
//       ["Week 2", 30],
//       ["Week 3", 20],
//     ],
//   },
// ];
// const series = [
//   {
//     name: "Product A",
//     data: [
//       {
//         name: "January",
//         y: 50,
//         drilldown: "productA-jan",
//       },
//       {
//         name: "February",
//         y: 60,
//         drilldown: "productA-feb",
//       },
//       {
//         name: "March",
//         y: 70,
//         drilldown: "productA-mar",
//       },
//     ],
//   },
//   {
//     name: "Product B",
//     data: [
//       {
//         name: "January",
//         y: 80,
//         drilldown: "productB-jan",
//       },
//       {
//         name: "February",
//         y: 90,
//         drilldown: "productB-feb",
//       },
//       {
//         name: "March",
//         y: 100,
//         drilldown: "productB-mar",
//       },
//     ],
//   },
// ];
